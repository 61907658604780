<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="540px"  
  >
    <v-card class="grey lighten-2">
      <v-card-title class="justify-center colorCustom2--text ">
        <div class="text-h6 text-center pt-n12" >IMPORTANTE </div>
      </v-card-title>
      <v-card-text>
          <div class="text-subtitle-2 colorCustom2--text" style="white-space: normal">Automóviles modelos entre 1994 y 1999 inclusive quedan exentos del impuesto automotor a partir del año 2022 por ordenanza 37628/22 (no recibirán boleta de pago).</div>
          <div class="text-subtitle-2 colorCustom2--text mt-4" style="white-space: normal">Si posee deuda por años anteriores, podrá regularizarla por este medio.</div>          
      </v-card-text>
      <v-card-actions class="justify-center">
        <botonCerrar @click.native="agree"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    components: {
      botonCerrar: () => import('./botonCerrar.vue'),
    },
    data () { return {
        show: false,
    }},    
    methods: {
      open () {
        this.show = true  
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree () {
        this.resolve(true)
        this.show = false
      },
    },    
  }
</script>